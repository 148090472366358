import {useState,useEffect} from 'react'
import {useNavigate } from 'react-router-dom';
import { trackPageView } from '../../analytics';
import './Dashboard.css'
//import env from 'react-dotenv'
import Sidedash from '../../components/Sidedash/Sidedash';
import LayoutDashCont from '../../components/LayoutDashCont/LayoutDashCont';


const Dashboard = ({passLoginStatus,loginStatus, generationStatus,handlelogin}) => {
  console.log('DASHBOARD=> logged',loginStatus)
  console.log('DASHBOARD=> hasGeneration',generationStatus)

  useEffect(() => {
      trackPageView('/dashboard'); // Indique la route ou la page
  }, []);

  let navigate = useNavigate();


  /**
   * State hooks for managing user login redirection and user information.
   * 
   * @param {boolean} goToLogin - State for determining if the user should be redirected to the login page.
   * @param {Function} setGoToLogin - Function to update the goToLogin state.
   * @param {Object|boolean} userInfos - State for storing user information retrieved from the server. False if not set.
   * @param {Function} setUserInfos - Function to update the userInfos state.
   */
  const [goToLogin,setGoToLogin] = useState(false)
  const [userInfos,setUserInfos] = useState(false)
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  /**
   * useEffect hook to fetch user information from the server.
   * 
   * This side effect runs once when the component mounts. It sends a GET request
   * to the server endpoint `${process.env.REACT_APP_BACK_URL}/getinfosuser` to retrieve
   * user information. The response is expected to include user details or an 
   * expiration notice.
   * 
   * If the session has expired, it triggers a state change to redirect the user 
   * to the login page. Otherwise, it updates the state with the retrieved user
   * information.
   * 
   * @async
   * @function
   * @returns {void}
   */
  useEffect(() => {
    console.log("infos_user lancé");
    const infos_user = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACK_URL}/getinfosuser`, {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();
        if (data.expired) {
          console.log('session expired')
          setGoToLogin(true);
        } else {
          console.log('logged IN',data);
          setUserInfos(data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    infos_user();
  }, []);


  /**
   * useEffect hook to manage the login redirection.
   * 
   * This effect runs whenever the `goToLogin` state changes. When triggered, it
   * calls the `handlelogin` function to set the login status to false, and uses 
   * the `navigate` function to redirect the user to the login page.
   * 
   * @function
   * @name useEffect
   * @listens {boolean} goToLogin - State that determines if the user should be redirected to the login page.
   * @listens {Function} handlelogin - Function to update the login status.
   * @listens {Function} navigate - Navigation function from react-router-dom for handling page redirection.
   * @returns {void}
   */
  useEffect(() => {
    console.log("Valeur de goToLogin:", goToLogin);
    if (goToLogin) {
      //handlelogin(false);
      navigate('/login');
    }
  }, [goToLogin, handlelogin, navigate]);

  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  /**
   * JSX layout for the Dashboard component.
   * 
   * This section renders the main dashboard view. It includes a `Sidedash` component
   * and conditionally renders the `LayoutDashCont` component if `userInfos` is available.
   * If `userInfos` is not set, it renders an empty fragment.
   * 
   * @returns {JSX.Element} - The JSX layout for the Dashboard.
   */
  return (
    <section className="dashboard">
      <Sidedash passLoginStatus={passLoginStatus} isActive={isSidebarActive} handleToggle={toggleSidebar} />
      {userInfos ? (<LayoutDashCont userInfos={userInfos} handleToggle={toggleSidebar}/>) : (<></>) }
    </section>
  );
}

export default Dashboard