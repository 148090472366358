import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = (trackingId) => {
  ReactGA.initialize(trackingId);
};

// Track a page view
export const trackPageView = (pagePath) => {
  ReactGA.send({ hitType: 'pageview', page: pagePath });
};

// Track a custom event
export const trackEvent = (action, category, label, value) => {
  ReactGA.event({
    action,
    category,
    label,
    value,
  });
};
