import React, { useState, useEffect,useRef,useCallback } from 'react';
import { trackEvent } from '../../analytics';
import { RoadMap } from '../RoadMap/RoadMap';
import mic from '../../assets/img/mic.svg';
import './Step1.css';
import axios from 'axios';
import {DoubleRightOutlined} from '@ant-design/icons'
export const Step1 = ({ handleClick, setThemeWebsite, step, setStepValid, setErrorMsg, goToStep }) => {
console.log('step1',goToStep)



/*
  const prompts = [
        'PetPalace is a luxurious pet hotel and daycare center offering comfortable and safe accommodations. Our experienced staff provides personalized service and attention to each furry friend. From basic pampering to premium indulgence, we guarantee a royally treated experience for your beloved companion.',
        'FreshFitCo is a local wellness retailer specializing in athlete-centric activewear and sustainable gym equipment for the modern fitness enthusiast. Founded by an avid athlete, we aim to provide high-quality gear and accessories that simplify your path to peak performance. Browse our collection today!',
        'EasyGrow is an online platform that connects gardeners with local gardening communities, offering resources and services for plant breeding, garden maintenance, and sustainable horticulture. Built on principles of organic gardening, climate resilience, and environmental stewardship, EasyGrow partners with public gardens, farmers’ markets, and wellness enthusiasts to promote synergized, holistic living.',
        'We offer environmentally friendly products and services to help individuals and businesses reduce their carbon footprint. Ourgoal is to create a sustainable future for our planet. GreenCycle provides eco-friendly packaging solutions, solar panel installation, and green recycling services.',
      ]
  */
  const [currentIdea, setCurrentIdea] = useState('');
  const textareaRef = useRef(null);
  
  /**
   * Fetches a new business idea from the backend server and updates the state with the new idea.
   * This function utilizes useCallback to memoize the function so it doesn't get recreated on every render.
   *
   * The function makes an asynchronous GET request to the backend server to retrieve a new idea.
   * On success, it updates the current business idea in the state.
   * On failure, it logs the error to the console and sets an error message in the state.
   * 
   * Endpoint: GET `${process.env.REACT_APP_BACK_URL}/business`
   * 
   * Dependencies:
   * - setCurrentIdea: Function to update the current business idea in the state.
   * - setErrorMsg: Function to set error message in the state in case of request failure.
   * 
   * @async
   * @function
   * @returns {Promise<void>}
   * @throws Will throw an error if the GET request fails.
   */
  const fetchNewIdea = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/business`, { withCredentials: true });
      setCurrentIdea(`${response.data.business.description} Address  : ${response.data.business.address}`);
      console.log('generateur idee',response.data)
    } catch (error) {
      console.error('Error fetching new idea:', error);
      setErrorMsg('Failed to fetch new idea. Please try again later.');
    }
  }, [setErrorMsg]);


  /**
   * This useEffect hook runs on component mount and checks for a stored 
   * 'themeWebsite' value in localStorage. If a theme is found, it sets the 
   * theme in the state and marks the step as valid while clearing any error 
   * messages. If no theme is found, it displays an error message indicating 
   * that the description can't be empty.
   * 
   * Dependencies:
   * - setThemeWebsite: Function to set the theme in the state.
   * - setStepValid: Function to mark the step as valid or invalid.
   * - setErrorMsg: Function to set any error messages.
   */
  useEffect(() => {
    const theme = localStorage.getItem('themeWebsite');
    if (theme) {
      setThemeWebsite(theme);
      setStepValid(true);
      setErrorMsg('');
    } else {
      setErrorMsg("Description can't be empty...");
    }
      // Fetch initial idea
      fetchNewIdea();
  }, [setThemeWebsite, setStepValid, setErrorMsg, fetchNewIdea]);



  /**
   * Handles the recording of the website description.
   * This function is triggered whenever the user inputs a description.
   * It stores the description in localStorage, updates the theme in the state,
   * and checks if the description is non-empty to mark the step as valid or invalid.
   * Additionally, it sets an error message if the description is empty.
   *
   * @param {string} description - The description input by the user.
   * Dependencies:
   * - setThemeWebsite: Function to set the theme in the state.
   * - setStepValid: Function to mark the step as valid or invalid.
   * - setErrorMsg: Function to set any error messages.
   */
  const handleRecordDescription = (description) => {
    localStorage.setItem('themeWebsite', description);
    setThemeWebsite(description);
    if (description !== '') {
      setStepValid(true);
      setErrorMsg('');
    } else {
      setErrorMsg("Description can't be empty...");
      setStepValid(false);
    }
  };



  /**
   * Handles the event when the "Idea" text is clicked.
   * This function triggers the fetching of a new business idea
   * by calling the `fetchNewIdea` function, which updates the state with the new idea.
   *
   * Dependencies:
   * - fetchNewIdea: Function to fetch and update the state with a new business idea.
  const handleIdeaClick = () => {
    trackEvent('click', 'AutoIdeaByGroq', 'User ask for idea generator', 1);
    fetchNewIdea();
  };
  
  */
  
  /**
   * Copies the current business idea to the description input field.
   * This function sets the current business idea as the description by calling
   * the `handleRecordDescription` function. It then directly updates the 
   * value of the textarea input field to reflect this idea.
   *
   * Dependencies:
   * - handleRecordDescription: Function to record the description in the state and localStorage.
   * - currentIdea: The current business idea fetched from the backend.
   * - textareaRef: Ref to the textarea element to be updated.
  const handleIdeaCopy = () => {
    handleRecordDescription(currentIdea);
    if (textareaRef.current) {
      textareaRef.current.value = currentIdea;
    }
  };
  */


  return (
    <div className="step1">
      <h1>DESCRIBE YOUR BUSINESS</h1>
      <div className='input-parent-cont'>
        <div className="input-parent">
          <textarea
            ref={textareaRef}
            onChange={(e) => handleRecordDescription(e.target.value)}
            placeholder={"eg:" + currentIdea}
          ></textarea>
            {/**
          <div>
            <img src={mic} alt="Microphone" style={{ filter: 'grayscale(1)' }} />
          </div>
            */}
        </div>
      <div>
        <button className="gradient-button" onClick={handleClick}>
          Next &nbsp;<DoubleRightOutlined />
        </button>
        
      </div>
      </div>
        {/**  
      <p className="idea-cont" >
        Idea: Extreme closeup footage of a young sailor woman with a concerned expression during a rainstorm.
        <span onClick={handleIdeaClick}>Idea:</span> <span className='idea' onClick={handleIdeaCopy}>{currentIdea}</span>
      </p>
        */}
      <RoadMap stepsCompleted={step} goToStep={goToStep}></RoadMap>
    </div>
  );
};
