import React, { useState, useEffect, useCallback } from 'react'
import { Alert } from 'antd'
import './MainCard.css'
import { Step1 } from '../Step1/Step1'
import { Step2 } from '../Step2/Step2'
import { Step3 } from '../Step3/Step3'
import { Step4 } from '../Step4/Step4'
import { Step5 } from '../Step5/Step5'
import { trackEvent } from '../../analytics'

export const MainCard = ({ loggedStatus, changeLoginStatus, setHasGeneration }) => {
  //console.log('changeLoginStatus',changeLoginStatus)
  //ETATS
  const [step,setStep] = useState(1)
  // GESTION DES ERREURES
  const [stepValid,setStepValid] = useState(false)
  const [throwError,setThrowError] = useState(false)
  const [errorMsg,setErrorMsg] = useState('')
  //TRANSITIONS
  const [animationClass, setAnimationClass] = useState('fade-in');
  const [errorAnimationClass, setErrorAnimationClass] = useState('');
  //STEP1 THEME DU SITE
  const [themeWebsite,setThemeWebsite]            = useState('')
  //STEP2 LOGO DU SITE
  const [infoLogoWebsite, setInfoLogoWebsite]     = useState('')
  const [colorLogoWebsite, setColorLogoWebsite]   = useState('')
  const [urlLogoWebsite, setUrlLogoWebsite]       = useState('')
  
  // //STEP3 NOM DU SITE/ CONTACT
   const [nameWebsite,setNameWebsite]              = useState('')
   const [shopEmail, setShopEmail]                 = useState('')
   const [shopPhone, setShopPhone]                 = useState('')
  // //5 RESEAUX SOCIAUX PAR DEFAULT
  // const [linkFacebook, setLinkFacebook]           = useState('https://www.facebook.com/')
  // const [linkInsta,       setLinkInsta]           = useState('https://www.instagram.com/')
  // const [linkTwitter,   setLinkTwitter]           = useState('https://twitter.com/')


  /**
   * useEffect hook to load data from localStorage on component mount.
   *
   * The useEffect hook retrieves various form data from the localStorage 
   * and updates the corresponding state variables. Specifically, it:
   * 1. Retrieves the current step from localStorage and updates the `step` state.
   * 2. Retrieves the theme of the website from localStorage and updates the `themeWebsite` state.
   * 3. Retrieves the logo information, logo color, and logo URL from localStorage and updates `infoLogoWebsite`, `colorLogoWebsite`, and `urlLogoWebsite` states respectively.
   * 4. Retrieves the name of the website, the shop email, and the shop phone number from localStorage and updates the `nameWebsite`, `shopEmail`, and `shopPhone` states respectively.
   *
   * This hook ensures that the form state is persistent even if the user refreshes the page 
   * or comes back to the form at a later time.
   */
  useEffect(() => {
    const savedStep = localStorage.getItem('currentStep');
    const savedThemeWebsite = localStorage.getItem('themeWebsite');
    const savedInfoLogoWebsite = localStorage.getItem('infoLogoWebsite');
    const savedColorLogoWebsite = localStorage.getItem('colorLogoWebsite');
    const savedUrlLogoWebsite = localStorage.getItem('urlLogoWebsite');
    const savedNameWebsite = localStorage.getItem('nameWebsite');
    const savedShopEmail = localStorage.getItem('shopEmail');
    const savedShopPhone = localStorage.getItem('shopPhone');

    if (savedStep) setStep(Number(savedStep));
    if (savedThemeWebsite) setThemeWebsite(savedThemeWebsite);
    if (savedInfoLogoWebsite) setInfoLogoWebsite(savedInfoLogoWebsite);
    if (savedColorLogoWebsite) setColorLogoWebsite(savedColorLogoWebsite);
    if (savedUrlLogoWebsite) setUrlLogoWebsite(savedUrlLogoWebsite);
    if (savedNameWebsite) setNameWebsite(savedNameWebsite);
    if (savedShopEmail) setShopEmail(savedShopEmail);
    if (savedShopPhone) setShopPhone(savedShopPhone);
  }, []);


  //COMPORTEMENTS

  /**
   * Handles click events to transition between steps in a multi-step form.
   *
   * This function performs the following:
   * 1. Checks if the current step is valid (`stepValid`).
   *    - If valid:
   *      - Clears any error message and hides the error alert.
   *      - Triggers a fade-out animation for the current step.
   *      - After the animation delay, it advances to the next step, 
   *        updates localStorage with the new step, resets the validation state,
   *        and triggers a fade-in animation for the new step.
   *    - If not valid:
   *      - Triggers an error alert with a fade-in-down animation.
   *      - After 2000ms, switches the error animation to fade-out-up.
   *
   * @param {Object} e - The event object.
   */
  const handleClick = (e) => {
    if (stepValid) {
      console.log('step:',step)
      trackEvent(step === 4 ? 'Generate' : 'Next' , `Step_${step}`, 'User submitted this step', 1);
      setErrorMsg('')
      setThrowError(false)
      setAnimationClass('fade-out');
      setTimeout(() => {
        const nextStep = step + 1;
        setStep(nextStep);
        localStorage.setItem('currentStep', nextStep);
        setStepValid(false)
        setAnimationClass('fade-in');
      }, 100); // Le délai doit correspondre à la durée de l'animation
    } else {
      setErrorAnimationClass('fade-in-down');
      setThrowError(true);
      setTimeout(() => {
        setErrorAnimationClass('fade-out-up');
      }, 2000);
    }
  }


  // Fonction pour naviguer vers une étape spécifique
  const goToStep = useCallback((stepNumber) => {
    localStorage.removeItem('themeWebsite');
    localStorage.removeItem('infoLogoWebsite');
    localStorage.removeItem('colorLogoWebsite');
    localStorage.removeItem('urlLogoWebsite');
    localStorage.removeItem('nameWebsite');
    localStorage.removeItem('shopEmail');
    localStorage.removeItem('shopPhone');
    setThemeWebsite('');
    setInfoLogoWebsite('');
    setColorLogoWebsite('');
    setUrlLogoWebsite('');
    setNameWebsite('');
    setShopEmail('');
    setShopPhone('');
    setStep(stepNumber);
    localStorage.setItem('currentStep', stepNumber);
  }, []);
  
  
  const handleStep5Error = useCallback((message) => {
    setErrorMsg(message);
    setThrowError(true);
    setErrorAnimationClass('fade-in-down');
    setTimeout(() => {
      setErrorAnimationClass('fade-out-up');
      setTimeout(() => {
        setThrowError(false);
        goToStep(1);
      }, 1000);
    }, 2000);
  }, [goToStep]);

//const handleStep5Error = message => console.log(message) 
  //LOGIQUE DE RENDU


  /**
   * Renders the appropriate step component based on the current step state.
   *
   * This function uses a switch statement to determine which step component to render.
   * Each step component is passed several props including:
   * - `handleClick`: Function to handle click events for transitioning steps.
   * - `setStepValid`: Function to set the validation state of the current step.
   * - `setErrorMsg`: Function to set the error message in case of validation failure.
   * Additionally, specific props are passed to each step component based on the data they require.
   *
   * @returns {JSX.Element} The JSX element corresponding to the current step in the form.
   */
  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 handleClick={handleClick} setThemeWebsite={setThemeWebsite} step={step} setStepValid={setStepValid} setErrorMsg={setErrorMsg} goToStep={goToStep}/>;
      case 2:
        return <Step2 handleClick={handleClick} 
                      step={step} 
                      setInfoLogoWebsite={setInfoLogoWebsite} 
                      setColorLogoWebsite={setColorLogoWebsite} 
                      setUrlLogoWebsite={setUrlLogoWebsite} 
                      setStepValid={setStepValid} 
                      setErrorMsg={setErrorMsg}
                      urlLogoWebsite={urlLogoWebsite}
                      infoLogoWebsite={infoLogoWebsite}
                      colorLogoWebsite={colorLogoWebsite}
                      goToStep={goToStep}
                />;
      case 3:
        return <Step3 handleClick={handleClick} 
                      step={step} 
                      setNameWebsite={setNameWebsite}
                      setShopEmail={setShopEmail}
                      setShopPhone={setShopPhone}
                      setStepValid={setStepValid} 
                      setErrorMsg={setErrorMsg}
                      nameWebsite={nameWebsite}
                      shopEmail={shopEmail}
                      shopPhone={shopPhone}
                      goToStep={goToStep}
                />;
      case 4:
        return <Step4 
                  handleClick={handleClick} 
                  step={step}
                  setNameWebsite={setNameWebsite}
                  setThemeWebsite={setThemeWebsite}
                  setInfoLogoWebsite={setInfoLogoWebsite} 
                  setShopEmail={setShopEmail}
                  setShopPhone={setShopPhone}
                  setStepValid={setStepValid} 
                  setErrorMsg={setErrorMsg}
                  nameWebsite={nameWebsite}
                  themeWebsite={themeWebsite}
                  infoLogoWebsite={infoLogoWebsite}
                  shopEmail={shopEmail}
                  shopPhone={shopPhone}
                  goToStep={goToStep}

                />;
      case 5:
        return <Step5
                  step={step}  
                  nameWebsite={nameWebsite}
                  themeWebsite={themeWebsite}
                  urlLogoWebsite={urlLogoWebsite}
                  infoLogoWebsite={infoLogoWebsite}
                  colorLogoWebsite={colorLogoWebsite}
                  shopEmail={shopEmail}
                  shopPhone={shopPhone}
                  setStepValid={setStepValid} 
                  setErrorMsg={setErrorMsg}
                  loggedStatus={loggedStatus} 
                  setHasGeneration={setHasGeneration}
                  goToStep={goToStep}
                  setThrowError={setThrowError}
                  handleStep5Error={handleStep5Error} 
                />;
      default:
        return <Step1 handleClick={handleClick} setThemeWebsite={setThemeWebsite} step={step} />;
    }
  };

  // Gerer l animation des messages d erreurs
  /**
   * Handles the end of the animation for error messages.
   * Specifically, it toggles off the error display when
   * the error message fade-out animation completes.
   *
   * This prevents the error message from being shown after the
   * fade-out animation is done, ensuring a clean user interface.
   *
   * The function checks if the current error animation class is 'fade-out-up'.
   * If it is, it sets the `throwError` state to false, effectively hiding the error message.
   */
  const handleAnimationEnd = () => {
    if (errorAnimationClass === 'fade-out-up') {
      setThrowError(false);
    }
  };

  //RENDU
  return (
    <div className={`steps-home ${animationClass}`}>
    {throwError && (
      <div className={`error-cont ${errorAnimationClass}`} onAnimationEnd={handleAnimationEnd}>
        <Alert message="Error" description={errorMsg} type="error" showIcon />
      </div>
    )}
    {renderStep()}
  </div>
  )
}
