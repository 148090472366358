import './Home.css'
import { Footer } from '../../components/Footer/Footer';
import { MainCard } from '../../components/MainCard/MainCard';
import React, { useEffect } from 'react';
import { trackPageView } from '../../analytics';

const Home = ({loggedStatus, changeLoginStatus,setHasGeneration}) => {

    /**
     * useEffect hook to track page views specifically for the '/generator' route.
     * This effect runs only once when the component mounts, due to the empty dependency array.
     * Calls the trackPageView function with the '/generator' path to log page views for analytics.
     */
    useEffect(() => {
        trackPageView('/generator'); // Indique la route ou la page
    }, []);

return (
    <>
        <MainCard loggedStatus={loggedStatus} changeLoginStatus={changeLoginStatus} setHasGeneration={setHasGeneration}></MainCard>
        <Footer></Footer>
    </>
)
};

export default Home;