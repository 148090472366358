import React, { useState } from 'react';
import { AutoComplete, Input, message, ConfigProvider } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import './DomainList.css';

// Liste des options (fournisseurs de domaines)
const domainProviders = [
  '1&1 IONOS', '101domain', '1Blu', '34SP.com', 'A Small Orange', 'A2 Hosting',
  'AccuWeb Hosting', 'Active24', 'Active24.cz', 'AEserver', 'Ahsay', 'Alibaba Cloud',
  'All-Inkl.com', 'AlphaNet', 'Amen.fr', 'APC Hosting', 'Arvixe', 'ArubaCloud',
  'Atlantic.net', 'Above.com', 'Baltneta', 'Banahosting', 'BigRock', 'BigScoots',
  'Bizland', 'Bluehost', 'Bluehost MENA', 'B-one.net', 'Byethost', 'Checkdomain.de',
  'CherryServers', 'Cloudflare', 'CloudSigma', 'Cloudways', 'Contabo', 'CoolHandle',
  'Crazy Domains', 'CrazyDomains', 'CSC Global', 'Cybersite', 'Czechia', 'Cyon', 'Dada',
  'Daily.co.uk', 'DailyRazor', 'Datanet.co.uk', 'DirectAdmin Hosting', 'Directnic',
  'Dhosting.pl', 'DigitalOcean', 'Dot.tk', 'DotEasy', 'DreamHost', 'Duda', 'Dynadot',
  'EasyDNS', 'EasyHost.be', 'Easyname', 'Easyspace', 'EasyWP', 'EDIS GmbH', 'ElanData',
  'Elkdata', 'eNom', 'Enom', 'EuroDNS', 'FastComet', 'Fasthosts', 'FatCow',
  'Fabulous.com', 'Flywheel', 'Forpsi', 'Freenom', 'G-Core Labs', 'Gandi', 'Gandi.net',
  'GlowHost', 'GoDaddy', 'GreenCloudVPS', 'GreenGeeks', 'GridPane', 'Hawk Host',
  'Heart Internet', 'Hetzner Online', 'Hexonet', 'Hover', 'Host4u', 'Hostazor', 'HostDime',
  'HostEurope', 'HostGator', 'HostGator MENA', 'HostHatch', 'Hosticor', 'Hostinger',
  'HosterPK', 'HostForLIFE', 'Hostirian', 'Hostiso', 'HostMantis', 'HostMonster', 'HostNine',
  'HostPapa', 'HostSailor', 'HostSG', 'HostUpon', 'HostUS', 'HostWinds', 'HyperNode',
  'IBM Cloud', 'ICANN Registrar', 'ICDSoft', 'InfinityFree', 'InterServer', 'IOZoom',
  'Jagoan Hosting', 'Jimdo', 'Joker.com', 'JustHost', 'Kamatera', 'Key-Systems GmbH',
  'Kinsta', 'KnownHost', 'Leaseweb', 'LCN.com', 'LightSail', 'Linode', 'Liquid Web',
  'LWS', 'MachHost', 'Madaar', 'Magento', 'MarkMonitor', 'Mazinohost', 'Media Temple',
  'Melbourne IT', 'Metanet', 'MilesWeb', 'Mochahost', 'MyDomain', 'Name.com', 'NameBright',
  'Namecheap', 'Namecheap MENA', 'NameSilo', 'Names.co.uk', 'NamesPro.ca', 'Netcup', 'Netim',
  'NetRegistry', 'NetUHost', 'Network Solutions', 'Nexcess', 'Nginx', 'Nic.io', 'Online.net',
  'Onyxhost', 'OnlyDomains', 'Oracle Cloud', 'OrangeWebsite', 'OVHcloud', 'Panthur',
  'Pantheon', 'Plesk', 'Porkbun', 'PowWeb', 'Pressable', 'ProFreeHost', 'ProHoster', 'PTD.net',
  'RamNode', 'ReadySpace', 'Rebel.com', 'Register365', 'Register.com', 'Register.it',
  'ResellerClub', 'RoseHosting', 'Savana.cz', 'Sakaryanet', 'Safenames', 'Scala Hosting',
  'Scaleway', 'Serveriai.lt', 'Shinjiru', 'Site5', 'SiteGround', 'SkySilk', 'SotCon',
  'Stablehost', 'STRATO', 'SuperHosting', 'Swisscom', 'Synergy Wholesale', 'Telehouse.cz',
  'Temok', 'Tencent Cloud', 'Time4VPS', 'TransIP', 'Tucows Domains', 'TurnKey Internet',
  'TSOhost', 'TPP Wholesale', 'uCloud', 'UK2.net', 'United-Domains AG', 'Uniregistry',
  'UpCloud', 'UHostFull', 'Veeble', 'VeeroTech', 'Verizon', 'Versio', 'VentraIP',
  'Vidahost', 'Vimexx', 'Virtual Machines', 'Virtualmin', 'Vivid Hosting', 'Vodien',
  'Voxility', 'VPSDime', 'Vultr', 'Web.com', 'Web4Africa', 'WebHostNepal', 'WebHostingBuzz',
  'Webhosting.dk', 'Webnode', 'Weebly', 'WEDOS', 'WHMCS', 'Wix', 'World4You', 'WP Engine',
  'Yahoo Small Business', 'Yola', 'ZNetLive', 'Zone.ee'
];

const DomainList = ({setChoosenDomain}) => {
  const [options, setOptions] = useState([]);
  const [canOpen,setCanOpen]  = useState(true)

  /**
   * Handles the search functionality for filtering and displaying the domain providers.
   *
   * This function takes a search input value, filters the list of domain providers 
   * based on the input, and updates the options state with the filtered results.
   *
   * @param {string} value - The search input value used to filter domain providers.
   */
  const handleSearch = (value) => {
    const filteredOptions = domainProviders
      .filter(provider => provider.toLowerCase().includes(value.toLowerCase()))
      .map(provider => ({ value: provider }));
    
    setOptions(filteredOptions);
  };


  /**
   * Handles the popup behavior for the autocomplete input.
   *
   * This function checks if the input value is an empty string. If the value is
   * empty, it sets a timeout to re-enable opening the popup after a delay of 2 seconds.
   *
   * @param {string} value - The current value of the autocomplete input.
   */
  const handlePopup = (value) => {
    if(value === ''){
      setTimeout(()=>{setCanOpen(true)},2000)
    }
  }



  /**
   * Handles the search button click event.
   *
   * This function is called when the search button is clicked. It performs the following actions:
   * 1. Disables the popup for the autocomplete input.
   * 2. Updates the chosen domain with the search input value.
   * 3. Displays a success message with the entered value.
   *
   * @param {string} value - The search input value entered by the user.
   */
  const onSearchButtonClick = (value) => {
    if(value !== ''){
      setCanOpen(false)
      setChoosenDomain(value);
      message.info(`You entered: ${value}. Please wait...`);
    }
  };


  const customTheme = {
    token: {
      controlHeight: 60,
      colorPrimary: `#E16400`,
      borderRadius: 9.38,
      fontSize: 19.26,
      fontFamily: `gotham pro`,
      fontWeight: 500,
    },
    components: {
      Input: {
        boxShadow: 'none', // Supprimer le box-shadow
        borderColor: '#E0E4EC',
        borderRadius: 9.38,
        fontSize: 19.26,
        fontFamily: 'gotham pro',
        fontWeight: 500,
      },
      Button:{
        fontSize: 19.26,
        fontFamily: 'gotham pro',
        fontWeight: 700,
        background: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
        backgroundColor: 'transparent', // Pour assurer que le dégradé est bien appliqué
        border: 'none', // Assure que le dégradé est visible sans bordure
      }
    },
  };

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <AutoComplete
          options={options}
          onSearch={handleSearch}
          onChange={handlePopup}
          style={{ width: 500 }}
          open={canOpen}
        >
          <Input.Search className='s-dom'
            size="large"
            placeholder="Search Domain Providers"
            prefix={<GlobalOutlined style={{ fontSize: '24px' }} />}
            style={{ fontSize: '18px' }}
            enterButton="Submit"
            onSearch={onSearchButtonClick} // Quand le bouton est cliqué
          />
        </AutoComplete>
      </div>
    </ConfigProvider>
  );
};

export default DomainList;
