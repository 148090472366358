import React, { useState, useEffect, useCallback }  from 'react';
import { RoadMap } from '../RoadMap/RoadMap';
import './Step3.css';
import {DoubleRightOutlined} from '@ant-design/icons'

export const Step3 = ({handleClick, step, setNameWebsite, setShopEmail, setShopPhone, setStepValid, setErrorMsg, nameWebsite, shopEmail, shopPhone,goToStep}) => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  /**
   * Handles the event when the name of the shop is updated.
   * This function stores the provided shop name in localStorage and updates the state.
   * 
   * @param {string} shopName - The name of the shop to be set.
   */
  const handleName = (shopName) => {
    localStorage.setItem('nameWebsite', shopName);
    setNameWebsite(shopName);
  }

  /**
   * Handles the event when the email of the shop is updated.
   * This function stores the provided shop email in localStorage and updates the state.
   * 
   * @param {string} shopEmail - The email of the shop to be set.
   */
  const handleEmail = (shopEmail) => {
    localStorage.setItem('shopEmail', shopEmail);
    setShopEmail(shopEmail);
  }
  
  /**
   * Handles the event when the phone number of the shop is updated.
   * This function stores the provided shop phone number in localStorage and updates the state.
   * 
   * @param {string} shopPhone - The phone number of the shop to be set.
   */
  const handlePhone = (shopPhone) => {
    localStorage.setItem('shopPhone', shopPhone);
    setShopPhone(shopPhone);
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('*Invalid email format');
    } else {
      setEmailError('');
    }
  }

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?\d{2,3}?\d{4,10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('*Invalid phone number format');
    } else {
      setPhoneError('');
    }
  };

/**
 * Validates the current step by checking if the required fields are filled and valid.
 * Ensures that `nameWebsite` does not contain special characters.
 * If the `nameWebsite`, `shopEmail`, and `shopPhone` are all provided and valid, it sets the step as valid and clears any error messages.
 * If any of these fields are missing or invalid, it sets the step as invalid and displays an appropriate error message.
 *
 * @callback
 * @param {string} nameWebsite - The name of the website entered by the user.
 * @param {string} shopEmail - The email of the shop entered by the user.
 * @param {string} shopPhone - The phone number of the shop entered by the user.
 * @param {function} setStepValid - Function to set the validity state of the step.
 * @param {function} setErrorMsg - Function to set the error message.
 */
const validateStep = useCallback(() => {
  // Regular expression to check for special characters
  const specialCharRegex = /[^a-zA-Z0-9\s]/;

  // Validate nameWebsite to ensure it does not contain special characters
  const isNameValid = nameWebsite && !specialCharRegex.test(nameWebsite);

  if (isNameValid && shopEmail && shopPhone && !emailError && !phoneError) {
    setStepValid(true);
    setErrorMsg('');
  } else {
    setStepValid(false);
    if (!isNameValid) {
      setErrorMsg('Website name must not contain special characters.');
    } else {
      setErrorMsg('All fields are required, and formats must be valid.');
    }
  }
}, [nameWebsite, shopEmail, shopPhone, emailError, phoneError, setStepValid, setErrorMsg]);


  /**
   * Uses the `useEffect` hook to validate the step every time 
   * `nameWebsite`, `shopEmail`, or `shopPhone` changes.
   * The `validateStep` function is called to check if all required fields
   * are filled and to update the validity state and error message accordingly.
   *
   * Dependencies:
   * - `nameWebsite`: The current website name.
   * - `shopEmail`: The current shop email.
   * - `shopPhone`: The current shop phone number.
   * - `validateStep`: The validation function to call when dependencies change.
   */
  useEffect(() => {
    validateStep();
  }, [nameWebsite, shopEmail, shopPhone, emailError, phoneError, validateStep]);
  
  return (
    <div className="step3">
      <h1>Public contact details shown on your website</h1>
      <div className="step3-inputs-box">
        <input 
            type="text" 
            placeholder="Business Name" 
            onChange={(e)=> handleName(e.target.value)}
        />
        <input 
            type="text" 
            placeholder="Business email" 
            onChange={(e)=> handleEmail(e.target.value)}
            onBlur={(e)  => validateEmail(e.target.value)}
            onFocus={() => setEmailError('')}
        />
        {/*emailError && <span className="error-message">{emailError}</span>*/}
        <input 
            type="text" 
            placeholder="Business phone number" 
            onChange={(e)=> handlePhone(e.target.value)}
            onBlur={(e)  => validatePhone(e.target.value)}
            onFocus={() => setPhoneError('')}
            />
        {/*phoneError && <span className="error-message">{phoneError}</span>*/}
      </div>
      <div>
        <button className="gradient-button"
          onClick={handleClick}>Next&nbsp;<DoubleRightOutlined/>
        </button>
      </div>
      <RoadMap stepsCompleted={step} goToStep={goToStep}/>
    </div>
  );
};
