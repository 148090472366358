import {  useState,useEffect } from "react";
import { Layout, Button, message} from "antd";
import { useLocation,useNavigate,Link } from 'react-router-dom';
import { SettingOutlined } from "@ant-design/icons"
import env from "react-dotenv";
import './Navbar.css'
import midlogo from '../../assets/img/logo.svg'
import smallmidlogo from '../../assets/img/slogo.svg'
//import ReactGA from 'react-ga';


const Navbar =  ({loggedStatus, passLoginStatus, hasGeneration}) => {
  /****************************ANALYTIC***************************** */
  //const location = useLocation();
  //useEffect(() => {
  //  ReactGA.pageview(location.pathname + location.search);
  //}, [location]);
/******************************************************************* */

  //console.log('user already has generation :',hasGeneration)
  const { Header } = Layout;
  //Etats des composants
  const [onDashboard,setOnDashboard] = useState()
  const [logoOn,setLogoOn] = useState(false)
  // va permettre de cacher la nav sur des elements 
  const [hiddenSpe, setHiddenSpe] = useState(false)

 
  // on recupere la route courante
  const infoLocation = useLocation()
  // Au lancement, on verifie si on est sur
  // le dashboard, si oui on passe onDashboard a true sinon a false
  useEffect(()=>{
    if(infoLocation.pathname === '/dashboard'){
      setOnDashboard(true)
    }else{
      setOnDashboard(false)
    }
    if(infoLocation.pathname === '/login' || infoLocation.pathname === '/register' || infoLocation.pathname === '/pass-recovery'){
      console.log('infolocpathnav:',infoLocation.pathname)
      setHiddenSpe(true)
      setLogoOn(true)
    }else{
      setLogoOn(false)
    }
   
  },[infoLocation])

  // pour pouvoir rediriger
  let navigate = useNavigate();


  // recuperation des infos de session
  let userInfos = false;
  if(loggedStatus){
    if(localStorage.getItem('user') ){
      userInfos = JSON.parse( localStorage.getItem('user') )
    }
  }
  


  ///////////////////////////////CLICK SUR ICONE ROUE CRANTEE /////////////////////////////
  const handleIconClick = () => { navigate('/dashboard'); };

  ///////////////////////////////CLICK SUR LOGOUT /////////////////////////////
  const handleLogout = async () => {
    //deconnection back
    const logout_resp = await fetch(`${env.REACT_APP_BACK_URL}/logout`,
    {
      method: 'GET',
      credentials: 'include',
    })
    .then(res=>res.json())
    .then(data => data)
    if(logout_resp.success){
      localStorage.removeItem('user');
      localStorage.removeItem('generation');
      localStorage.removeItem('auth');

      passLoginStatus(false)
      navigate('/login');
    }else{
      message.error('Impossible to logout ! please try later')
    }
  }

  const handleHome = async () => {
    navigate('/generator');
  }

 ///////////////////////////////RENDU  /////////////////////////////
  return (


      <Header className="steps-header">
        { logoOn && !loggedStatus ? <div></div> : null}
        {!onDashboard ?
        (<div className={`steps-head-left ${hiddenSpe ? 'spe-left' : '' }`}>

          { (loggedStatus && userInfos) ? userInfos.username : null}
        </div>)
          :
          (<div></div>)
        }
            <div className={`steps-head-mid ${hiddenSpe ? 'spe-mid' : '' }`}>
        <img className="blogo" src={midlogo} alt="Weebseat logo" onClick={handleHome}/>
        <img className="slogo"src={smallmidlogo} alt="Weebseat logo" onClick={handleHome}/>
        </div>
        <div className={`steps-head-right ${hiddenSpe ? 'spe-right' : '' }`}>
        {(loggedStatus && userInfos ) ? (
          <div className="block-right">
            {!onDashboard && hasGeneration ?
            (<SettingOutlined className={`setting-icn ${hiddenSpe ? 'cache' : '' }`} onClick={handleIconClick}/> )
            :
            (<div className="setting-icn" ></div>)
            }
            <Button  className={`gradient-button-logout ${hiddenSpe ? 'cache' : '' }`} onClick={handleLogout}>
              Logout
            </Button>
          </div>
        ):( (infoLocation.pathname === "/login" || infoLocation.pathname === "/register" || infoLocation.pathname === '/pass-recovery') ? '':
            
          <Link className="gradient-button" to="/login">Login</Link>
        )}
        </div>
      </Header>
    
  );
};

export default Navbar;