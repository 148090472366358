import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import './Cancel.css'; // Import du fichier CSS pour le style
import { trackPageView } from '../../analytics';

const Cancel = () => {
  const navigate = useNavigate();

  /**
   * This useEffect hook sets up an automatic redirection to the '/support' route
   * after a delay of 6 seconds (6000 milliseconds). It utilizes the setTimeout
   * function to initiate the redirection. The navigate function is used to
   * perform the redirection to the specified route. The effect ensures the
   * clearTimeout function is called to clean up the timer in case the component
   * is unmounted before the timer completes.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/support'); // Remplace '/support' par la route appropriée
    }, 6000);

    return () => clearTimeout(timer); // Nettoyage si le composant est démonté
  }, [navigate]);

    // This useEffect hook triggers a function to track the page view when the component mounts.
    // The trackPageView function is called with the argument '/cancel',
    // indicating that this particular page or route is being tracked.
    // The empty dependency array means this hook will run only once when the component is first rendered.
    useEffect(() => {
        trackPageView('/cancel'); // Indique la route ou la page
    }, []);

  return (
    <><div className="steps-home ">
        <div className="step1 step-cancel">
            <div className="cancel-container">
              <div className="cancel-content">
                <h1>⚠️ Subscription Cancelled ⚠️</h1>
                <p>We're sorry to see you go!</p>
                <p>If you changed your mind or need help, please contact support.</p>
              </div>
              <div className="cancel-spinner">
                <div className="spinner"></div>
                <p>Redirecting to support...</p>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cancel;
