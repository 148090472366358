import React from 'react';
import { Modal, Button } from 'antd';

/**
 * SubscriptionModal Component
 *
 * This component renders a modal that informs the user that the feature 
 * they are trying to access requires an active subscription. The modal 
 * appears when the 'visible' prop is true, and it is closed by calling 
 * the 'onClose' prop function. The modal includes an 'OK' button which, 
 * when clicked, also triggers the 'onClose' function.
 * 
 * Props:
 * - visible (boolean): Controls the visibility of the modal.
 * - onClose (function): Callback function to handle the closing of the modal.
 */

const SubscriptionModal = ({ visible, onClose, onCancel }) => {
    return (
        <Modal
            title="Your Weebseat is not yet Activated for public Access"
            open={visible}
            onOk={onClose}
            onCancel={onCancel}
            footer={[
                <Button key="ok" type="primary" onClick={onClose}>
                    SEE PLANS
                </Button>,
            ]}
        >
            <p className='modal-txt'>To go live, publish, manage, and showcase your Website online, select one of our available plans</p>
        </Modal>
    );
};

export default SubscriptionModal;
