import React from 'react'
import {  UserOutlined } from '@ant-design/icons';
import { useState,useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import env from "react-dotenv";
import axios from 'axios';


const EmailRecovery = ({setStep1, setStep2, setEmail, email}) => {
    const [counter, setCounter]            = useState(60);
    const [counterActive, setCounterActive]= useState(false) 
    const [btnActive, setBtnActive]        = useState(true)
    


    const SubmitEmail = async (email) => { 
      try {
        axios.defaults.withCredentials = true;
        return await axios.post(`${env.REACT_APP_BACK_URL}/send-otp`, {email});
      } catch (err) {
        return false;
      }
    };
  
    const handleSubmit = async () => {
      if(email !== ''){
        //bloquer le bouton d envoie 60 sec
          setBtnActive(false); // Désactiver le bouton
          setCounterActive(true)
        //console.log('email reco',email)
        const RecoveryAnswer = await SubmitEmail(email);
        //console.log( RecoveryAnswer.data )
        if (RecoveryAnswer.data.success) {
          // afficher email envoyé
          message.info(RecoveryAnswer.data.message)
          setTimeout(()=>{
            setStep1(false)
            setStep2(true)
          },2000)
          // afficher le composant 
        }else{
          //afficher msg error
          message.error(RecoveryAnswer.data.message)

        }
      }
    }
    useEffect(() => {
        let interval = null;
        if (!btnActive) {
          interval = setInterval(() => {
            setCounter((currentCountdown) => {
              if (currentCountdown <= 1) {
                setBtnActive(true);
                setCounterActive(false)
                clearInterval(interval);
                return 60; // Réinitialiser le compte à rebours à 60 secondes
              }
              return currentCountdown - 1;
            });
          }, 1000);
        }
        return () => clearInterval(interval);
      }, [btnActive]);



  return (
    <>
    <div className="form-cont" >
    <h3>Password recovery</h3>
    <Form
      name="recovery"
      initialValues={{ remember: true }}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
        onChange={(e) => setEmail(e.target.value)}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      
      <Form.Item >
        <Button  className="log" type="primary" disabled={btnActive ? false : true} htmlType="submit"  onClick={handleSubmit} > 
          Send OTP {counterActive ? (counter +' s') : ''}
        </Button>
      </Form.Item>
    </Form>
  </div>
    </>
  )
}

export default EmailRecovery