
import {ArrowRightOutlined} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
//import env from 'react-dotenv'
import '../StripeButton/StripeButton.css'

/**
 * Utilisation:
 * <StripeButton cycle={currentCycling} />
 * 
 * Le plan (PLAN_A ou PLAN_B) sera automatiquement mis à jour 
 * quand la prop cycle changera.
 */
const StripeButton = ({ cycle }) => {
  // Par défaut, mettons un plan "yearly"
  const [thePlan, setThePlan] = useState('PLAN_B')

  /**
   * Dès que cycle change (monthly/yearly), on met à jour thePlan
   */
  useEffect(() => {
    if (cycle === 'monthly') {
      setThePlan('PLAN_A')
    } else {
      // 'yearly' ou autre
      setThePlan('PLAN_B')
    }
  }, [cycle])

  /**
   * Au clic sur le bouton, on envoie thePlan au backend
   */
  const handleSubscribe = async () => {
    console.log('Cycle reçu :', cycle)
    console.log('Plan utilisé :', thePlan)

    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ plan: thePlan }),
      })

      if (!response.ok) {
        throw new Error('Failed to activate subscription')
      }

      const data = await response.json()
      console.log('Réponse session Stripe :', data)

      if (data.url) {
        // Redirection vers la session de paiement Stripe
        window.location.href = data.url
      } else {
        console.error('Problème lors de la création de la session :', data.error)
      }
    } catch (error) {
      console.error('Erreur lors de l’activation de l’abonnement :', error)
    }
  }

  return (
    <div className="stripeSubButton-cont">
        <div
        className="stripeSubButton" 
        onClick={handleSubscribe}
        >
        Choose this plan&nbsp;
        <ArrowRightOutlined />
      </div>
    </div>
  )
}

export default StripeButton
