import React,{useState,useEffect} from 'react'
import {  ConfigProvider,  message } from 'antd';
import DomainList from '../DomainList/DomainList';
import './MainDomain.css'
//import env from 'react-dotenv';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

/**
 * Returns a formatted date string representing the current date.
 * The format is "DD MMM" where DD is the day of the month,
 * and MMM is the abbreviated month name.
 *
 * @returns {string} The formatted date string.
 */
const getDate = () => {
    const today = new Date().toString().split(' ');
    return `${today[2]} ${today[1]}`;
};

const MainDomain = ({ userInfos }) => {

    console.log('userinfo mainDomain',userInfos)
    const [currentDate] = useState(getDate());
    const [choosenDomain,setChoosenDomain] = useState('')
    const [answeredCode,setAnsweredCode]   = useState(false)

    useEffect(() => {
        // Fonction pour faire la requête fetch
        const sendDomainConnection = async () => {
          if (choosenDomain) { // Ne pas envoyer de requête si choosenDomain est vide
            try {
              const response = await fetch(`${process.env.REACT_APP_BACK_URL}/domain/connection`, {
                method: 'POST',
                credentials: 'include', // Inclure les credentials pour l'authentification
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ provider: choosenDomain }), // Envoyer choosenDomain dans le corps
              });
              
              if (!response.ok) {
                throw new Error('Failed to connect domain');
              }
              
              const result = await response.json();
              if(result.success){
                if(result.message === 'Unknown provider'){
                    message.error(result.message)
                }else{
                    setAnsweredCode(result.message)
                    //console.log('Domain connection result:', result.message);
                }
            
              }else{
                message.error(`Error: ${result.message}`)
                console.log(`Error: ${result.message}`);
              }
            } catch (error) {
              console.error('Error connecting domain:', error);
            }
          }
        };
    
        // Appeler la fonction lorsque choosenDomain change
        sendDomainConnection();
      }, [choosenDomain]); // Déclencher l'effet quand choosenDomain change
    

  return (
    <ConfigProvider
    theme={{
        components: {
            Button: {
                colorPrimary: `#E16400`,
                colorPrimaryHover: `#E16400`,
                colorPrimaryActive: `#E16400`,
                lineWidth: 1,
                defaultBg:`#EDF2F6`,
                controlHeight:60,
                borderRadius:9.38,
                defaultBorderColor:`#E0E4EC`,
                fontSize:19.26,
                fontFamily:`gotham pro`,
                fontWeight:500,

            },
            Radio: {
                colorPrimary: `#E16400`,
                colorPrimaryHover: `#E16400`,
                colorPrimaryActive: `#E16400`,
                lineWidth: 1,
                borderRadius: 9.38,
                controlHeight: 60,
                fontSize: 19.26,
                fontFamily: `gotham pro`,
                fontWeight: 500,
                buttonBg: `#EDF2F6`,
                buttonCheckedBg: `#E16400`,
                buttonColor: `rgba(0, 0, 0, 0.88)`,
                buttonSolidCheckedColor: `#fff`,
                buttonPaddingInline: 15,
            },
        },
    }}
>
    <div className="main">
        <div className="dashboardPaymentSecTitle">
            <h1>Connect my domain</h1>
            <p>{currentDate}</p>
        </div>
        <div className="step7 reduit">
            <div className="payment-box2">
                <h1>How to connect my actual domain to my new Weebseat ?</h1>
                    <h4>Please submit your provider</h4>
                    <DomainList setChoosenDomain={setChoosenDomain}/>
                    <div>
                        {answeredCode ? (<ReactMarkdown children={answeredCode} remarkPlugins={[remarkGfm]} className="tutos"/>)  : ''}
                    </div>
                    
            </div>
        </div>
    </div>
</ConfigProvider>
  )
}

export default MainDomain