import React from 'react'
import { Button } from 'antd'
import './Unsubscribe.css'


const UnsubscribeBtn = ({unsubscribe}) => {
  return (
    <Button className='unsubscribe' danger onClick={unsubscribe}>Unsubscribe</Button>
  )
}

export default UnsubscribeBtn