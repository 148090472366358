import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { trackPageView } from '../../analytics';
import './Success.css'; // Import du fichier CSS pour le style

const Success = () => {
  const navigate = useNavigate();

  /**
   * useEffect hook to handle automatic redirection after a delay.
   * 
   * This effect sets up a timer when the component is mounted that will navigate 
   * the user to the '/dashboard' route after a 6-second delay. This provides the 
   * user ample time to read the success message before being redirected.
   * 
   * The `navigate` function from 'react-router-dom' is used to change the route. 
   * In case the component is unmounted before the timer ends, the cleanup function 
   * clears the timeout to prevent memory leaks or attempts to navigate when the 
   * component no longer exists.
   * 
   * Dependency: The effect depends on the `navigate` function. If the function 
   * changes, the effect will rerun.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard'); // Remplace '/dashboard' par la route correcte
    }, 6000);

    return () => clearTimeout(timer); // Nettoyage si le composant est démonté
  }, [navigate]);

  /**
   * useEffect hook to track conversion event using Google Analytics.
   * 
   * This effect runs only once when the component is mounted. It checks if the 
   * global `gtag` function is available, which indicates that Google Analytics 
   * is integrated on the page. If `gtag` is available, it triggers a conversion 
   * event with specific parameters such as:
   * - `send_to`: Identifier for the conversion event setup in Google Analytics.
   * - `value`: The monetary value of the conversion, set to 1.0.
   * - `currency`: The currency in which the value is measured, set to 'USD'.
   * 
   * The effect does not have any dependencies in its dependency array, ensuring
   * it runs only once on component mount and cleans up automatically.
   */
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11552032950/2LzbCIHtn4waELaRuIQr',
        value: 1.0,
        currency: 'USD',
      });
    }
  }, []);


  useEffect(() => {
      trackPageView('/success'); // Indique la route ou la page
  }, []);
  ///JSX///
  return (
    <><div className="steps-home ">
        <div className="step1 step-success">
            <div className="success-container">
              <div className="success-content">
                <h1>🎉 Subscription Successful! 🎉</h1>
                <p>Congratulations! Your subscription has been activated successfully.</p>
                <p>You will be redirected to your dashboard shortly.</p>
              </div>
              <div className="success-spinner">
                <div className="spinner"></div>
                <p>Redirecting...</p>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Success;
