import React, { useState, useEffect } from 'react';
import Navdash from '../Navdash/Navdash';
import MainSub from '../MainSub/MainSub';
//import env from 'react-dotenv';

const LayoutSubCont = ({ userInfos, handleToggle }) => {
  const [isSubValid, setIsSubValid] = useState(false);

  useEffect(() => {
    const fetchSubscriptionValidity = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACK_URL}/sub_valid`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const data = await response.json();
        console.log('retour requête:', data);
        
        setIsSubValid(data?.valid || false);
      } catch (error) {
        console.error('Erreur lors de la récupération de la validité de l\'abonnement:', error);
        setIsSubValid(false);
      }
    };

    fetchSubscriptionValidity();
  }, []);

  return (
    <div className='layout-container'>
      <Navdash userInfos={userInfos} handleToggle={handleToggle} />
      <MainSub userInfos={userInfos} isSubValid={isSubValid} />
    </div>
  );
};

export default LayoutSubCont;
